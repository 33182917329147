import Vue from 'vue'
import VueRouter from 'vue-router'

import { 
    ROUTE_LOGIN,
    ROUTE_HOME,
    ROUTE_BRANCH_OFFICES,
    ROUTE_BRANCH_OFFICESADD,
    ROUTE_BRANCH_OFFICESEDIT,
    ROUTE_BRANCH_WORKINGHOURS,
    ROUTE_USER_ADMIN,
    ROUTE_USER_CLIENTS,
    ROUTE_USER_FREELANCERS,
    ROUTE_USER_ADD,
    ROUTE_USER_EDIT,
    ROUTE_OPERATORS,
    ROUTE_OPERATOR_EDIT,
    ROUTE_TRANSPORTS,
    ROUTE_TRANSPORT_EDIT,
    ROUTE_UNITS,
    ROUTE_SERVICE_HOTELS,
    ROUTE_SERVICE_CIRCUITS,
    ROUTE_SERVICE_DAYTRIPS,
    ROUTE_SERVICE_TOURS,
    ROUTE_SERVICE_INTERNATIONALS,
    ROUTE_SERVICE_LOCKEDS,
    ROUTE_SERVICE_LOCKED_SHOW,
    ROUTE_SERVICE_EDIT,
    ROUTE_SERVICE_TRAVEL_DATE,
    ROUTE_SERVICE_EXIT_PLACES,
    ROUTE_SERVICE_EXIT_PLACE_PRICES,
    ROUTE_SERVICE_TRAVEL_UNIT,
    ROUTE_RESERVATION_HOTEL_INDEX,
    ROUTE_RESERVATION_HOTEL_CREATE,
    ROUTE_RESERVATION_HOTEL_SHOW,
    ROUTE_RESERVATION_HOTEL_EDIT,
    ROUTE_RESERVATION_CIRCUIT_INDEX,
    ROUTE_RESERVATION_CIRCUIT_CREATE,
    ROUTE_RESERVATION_CIRCUIT_SHOW,
    ROUTE_RESERVATION_CIRCUIT_EDIT,
    ROUTE_RESERVATION_DAYTRIPS_INDEX,
    ROUTE_RESERVATION_DAYTRIPS_CREATE,
    ROUTE_RESERVATION_DAYTRIPS_SHOW,
    ROUTE_RESERVATION_DAYTRIPS_EDIT,
    ROUTE_RESERVATION_TOURS_INDEX,
    ROUTE_RESERVATION_TOURS_CREATE,
    ROUTE_RESERVATION_TOURS_SHOW,
    ROUTE_RESERVATION_TOURS_EDIT,
    ROUTE_USER_PERMISSIONS,
    ROUTE_USER_BRANCHOFFICES,
    ROUTE_RESERVATION_INTERNATIONALS_INDEX,
    ROUTE_RESERVATION_INTERNATIONALS_CREATE,
    ROUTE_RESERVATION_INTERNATIONALS_SHOW,
    ROUTE_RESERVATION_INTERNATIONALS_EDIT,
    ROUTE_RESERVATION_FLIGHTS_INDEX,
    ROUTE_RESERVATION_FLIGHTS_CREATE,
    ROUTE_RESERVATION_FLIGHTS_SHOW,
    ROUTE_RESERVATION_FLIGHTS_EDIT,
    ROUTE_RESERVATION_OTHER_SERVICE_INDEX,
    ROUTE_RESERVATION_OTHER_SERVICE_CREATE,
    ROUTE_RESERVATION_OTHER_SERVICE_SHOW,
    ROUTE_RESERVATION_OTHER_SERVICE_EDIT,
    ROUTE_RESERVATION_SELECT_SEAT_INDEX,
    ROUTE_RESERVATION_NOTES,
    ROUTE_RESERVATION_PEOPLE,
    ROUTE_PROFILE_LIST,
    ROUTE_PROFILE_ADD,
    ROUTE_PROFILE_EDIT,
    ROUTE_RESERVATION_PASS_INDEX,
    ROUTE_RESERVATION_PAYMENTS,
    ROUTE_PROMOTIONS,
    ROUTE_COUPONS,
    ROUTE_COUPON_ADD,
    ROUTE_COUPON_EDIT,
    ROUTE_BANKS,
    ROUTE_BANK_ADD,
    ROUTE_BANK_EDIT,
    ROUTE_RESERVATION_LIST_PAYMENTS,
    ROUTE_BRANCH_BOXES,
    ROUTE_BOX_HISTORY,
    ROUTE_BOX_HISTORY_MOVE_LIST,
    ROUTE_SAVING,
    ROUTE_BANK_HISTORY_MOVE_LIST,
    ROUTE_RESERVATION_PAYMENT_TO_BROOKER,
    ROUTE_RESERVATION_PAYMENT_TO_BROOKER_VIEW,
    ROUTE_CONFIGURATIONS,
    ROUTE_PROFILE,
    ROUTE_PROMOTION_FOLDER,
    ROUTE_RESERVATION_CANCEL,
    ROUTE_CLIENT_LOGIN,
    ROUTE_CLIENT_TEMPLATE,
    ROUTE_CLIENT_DASHBOARD,
    ROUTE_CLIENT_RESERVATIONS,
    ROUTE_CLIENT_PROFILE,
    ROUTE_CLIENT_PAYMENTS,
    ROUTE_RESERVATION_PACKAGED_INDEX,
    ROUTE_RESERVATION_PACKAGED_CREATE,
    ROUTE_RESERVATION_PACKAGED_SHOW,
    ROUTE_RESERVATION_PACKAGED_EDIT,
    ROUTE_ACTIVITY,
    ROUTE_REPORTS,
    ROUTE_BRANCH_OFFICE_GALLERY,
    ROUTE_BRANCH_OFFICE_TEAM,
    ROUTE_RESERVATION_TRANSFER_INDEX,
    ROUTE_RESERVATION_TRANSFER_CREATE,
    ROUTE_RESERVATION_TRANSFER_SHOW,
    ROUTE_RESERVATION_TRANSFER_EDIT,
    ROUTE_PAYMENT_MULTIPLE,
    ROUTE_PAYMENT_MULTIPLE_ADD,
    ROUTE_SERVICE_QUOTE,
    ROUTE_SERVICE_QUOTE_ADD,
    ROUTE_SERVICE_QUOTE_EDIT,
    ROUTE_SERVICE_QUOTE_SHOW,
    ROUTE_RESERVATION_LOCKEDS_INDEX,
    ROUTE_RESERVATION_LOCKEDS_CREATE,
    ROUTE_RESERVATION_LOCKEDS_EDIT
} from "../enums/RouteNameEnums";

import { USER_ADMIN } from '@/enums/UserTypeEnums';
import { ACCESS_MENU } from "../validations/auth/AccessControlList";

Vue.use(VueRouter)

const guest = async (to, from, next) => {
    if (!localStorage.getItem("authToken")) {
        return next();
    } else {
        var user = JSON.parse(localStorage.getItem("userType"));
        
        if(user){
            if(user.user_type != USER_ADMIN){
                // console.log(user.user_type);
                // console.log(from);
                // console.log(to);
                return next({
                    name: ROUTE_CLIENT_TEMPLATE
                });
            }else{
                return next('/admin/dashboard');
            }
        }else{
            return next('/admin/dashboard');
        }
    }
};

const auth = async (to, from, next) => {
    if (localStorage.getItem("authToken")) {
        var user = JSON.parse(localStorage.getItem("userType"));
        if(user){
            if(user.user_type != USER_ADMIN){
                if(to.name.split("_")[1] != 'client'){
                    return next({
                        name: 'NotFound'
                    });
                }else{
                    return next();
                }
            }else{
                return next({
                    name: 'NotFound'
                });
            }
        }else{
            if(ACCESS_MENU(to.name.split("_")[1]) && to.name.split("_")[1] != 'client'){
                return next();
            }else{
                return next({
                    path: '/not-authorized'
                });
            }
        }
    } else {
        return next({
            path: '/'
        });
    }
};

const routes = [
    {
        path: "/",
        name: "AuthPage",
        beforeEnter: guest,
        redirect: '/login'
        //component: () => import('../components/frontend/home/HomePage.vue'),
    },
    {
        path: '/login',
        name: ROUTE_LOGIN,
        beforeEnter: guest,
        component: () => import('../components/auth/Login.vue')
    },
    {
        path: '/activar-cuenta/:tokenUser',
        name: 'ACTIVATED_USER',
        component: () => import('../components/users/ActivatedUser.vue'),
        props: true
    },
    {
        path: '/recuperar-contrasena/:tokenUser',
        name: 'RESET_PASSWORD',
        component: () => import('../components/users/ResetPassword.vue'),
        props: true
    },
    {
        path: '/not-authorized',
        name: 'NOT_AUTHORIZED',
        //beforeEnter: guest,
        component: () => import('../components/auth/NotAuthorized.vue')
    },
    { 
        path: '*', 
        name: 'NotFound',
        component: () => import('../components/home/NotFoundComponent.vue')
    },
    {
        path: '/admin',
        name: 'TemplateDashboard',
        component: () => import('../components/partials/TemplateDashboard.vue'),
        beforeEnter: auth,
        redirect: '/admin/dashboard',
        children:[
            {
                path        : 'dashboard',
                name        : ROUTE_HOME,
                beforeEnter : auth,
                component   : () => import('../components/home/Dashboard.vue')
            },

            //BRANCHOFFICES
            {
                path        : 'sucursales',
                name        : ROUTE_BRANCH_OFFICES,
                beforeEnter : auth,
                component   : () => import('../components/branch-offices/ListBranchOffices.vue')
            },
            {
                path        : 'sucursal/agregar',
                name        : ROUTE_BRANCH_OFFICESADD,
                beforeEnter : auth,
                component   : () => import('../components/branch-offices/AddBranchOffice.vue'),
                props       : {'idBranchOffice' : 0}
            },
            {
                path        : 'sucursal/:idBranchOffice/galeria',
                name        : ROUTE_BRANCH_OFFICE_GALLERY,
                beforeEnter : auth,
                component   : () => import('../components/branch-offices/GalleryBranchOffice.vue'),
                props       : true
            },
            {
                path        : 'sucursal/:idBranchOffice/equipo',
                name        : ROUTE_BRANCH_OFFICE_TEAM,
                beforeEnter : auth,
                component   : () => import('../components/branch-offices/TeamBranchOffice.vue'),
                props       : true
            },
            {
                path        : 'sucursal/:idBranchOffice/editar',
                name        : ROUTE_BRANCH_OFFICESEDIT,
                beforeEnter : auth,
                component   : () => import('../components/branch-offices/AddBranchOffice.vue'),
                props       : true
            },
            {
                path        : 'sucursal/:idBranchOffice/horarios',
                name        : ROUTE_BRANCH_WORKINGHOURS,
                beforeEnter : auth,
                component   : () => import('../components/branch-offices/WorkingHoursBranchOffice.vue'),
                props       : true
            },

            //USERS
            {
                path        : 'usuarios/administradores',
                name        : ROUTE_USER_ADMIN,
                beforeEnter : auth,
                component   : () => import('../components/users/administrators/ListAdministrators.vue'),
            },
            {
                path        : 'usuarios/clientes',
                name        : ROUTE_USER_CLIENTS,
                beforeEnter : auth,
                component   : () => import('../components/users/clients/ListClients.vue'),
            },
            // {
            //     path        : 'usuarios/agencias',
            //     name        : ROUTE_USER_AGENCIES,
            //     beforeEnter : auth,
            //     component   : () => import('../components/users/agencies/ListAgencies.vue'),
            // },
            /*{
                path        : 'usuarios/freelancers',
                name        : ROUTE_USER_FREELANCERS,
                beforeEnter : auth,
                component   : () => import('../components/users/freelancers/ListFreelancers.vue'),
            },*/
            {
                path        : `usuarios/:user_type/agregar`,
                name        : ROUTE_USER_ADD,
                beforeEnter : auth,
                component   : () => import('../components/users/partials/AddUsers.vue'),
                props       : true
            },
            {
                path        : `usuarios/:user_type/:idUser/editar`,
                name        : ROUTE_USER_EDIT,
                beforeEnter : auth,
                component   : () => import('../components/users/partials/EditUser.vue'),
                props       : true
            },
            {
                path        : `usuarios/:user_type/:idUser/permisos`,
                name        : ROUTE_USER_PERMISSIONS,
                beforeEnter : auth,
                component   : () => import('../components/users/administrators/UserPermissions.vue'),
                props       : true
            },
            {
                path        : `usuarios/:user_type/:idUser/sucursales`,
                name        : ROUTE_USER_BRANCHOFFICES,
                beforeEnter : auth,
                component   : () => import('../components/users/administrators/UserBranchOffices.vue'),
                props       : true
            },

            // OPERATORS
            {
                path        : `operadoras`,
                name        : ROUTE_OPERATORS,
                beforeEnter : auth,
                component   : () => import('../components/operators/ListOperators.vue')
            },
            {
                path        : `operadoras/:idOperator/editar`,
                name        : ROUTE_OPERATOR_EDIT,
                beforeEnter : auth,
                component   : () => import('../components/operators/AddOperators.vue'),
                props       : true
            },

            // CONVEYORS
            {
                path        : `transportadoras`,
                name        : ROUTE_TRANSPORTS,
                beforeEnter : auth,
                component   : () => import('../components/conveyors/ListConveyors.vue')
            },
            {
                path        : `transportadoras/:idConveyor/editar`,
                name        : ROUTE_TRANSPORT_EDIT,
                beforeEnter : auth,
                component   : () => import('../components/conveyors/AddConveyors.vue'),
                props       : true
            },

            //UNITS
            {
                path        : `unidades`,
                name        : ROUTE_UNITS,
                beforeEnter : auth,
                component   : () => import('../components/units/ListUnits.vue'),
            },

            //SERVICES
            {
                path        : `servicios/hoteles`,
                name        : ROUTE_SERVICE_HOTELS,
                beforeEnter : auth,
                component   : () => import('../components/services/hotels/ListHotels.vue'),
            },
            {
                path        : `servicios/circuitos`,
                name        : ROUTE_SERVICE_CIRCUITS,
                beforeEnter : auth,
                component   : () => import('../components/services/circuits/ListCircuits.vue'),
            },
            {
                path        : `servicios/pasadias`,
                name        : ROUTE_SERVICE_DAYTRIPS,
                beforeEnter : auth,
                component   : () => import('../components/services/daytrips/ListDaytrips.vue'),
            },
            {
                path        : `servicios/tours`,
                name        : ROUTE_SERVICE_TOURS,
                beforeEnter : auth,
                component   : () => import('../components/services/tours/ListTours.vue'),
            },
            {
                path        : `servicios/internacionales`,
                name        : ROUTE_SERVICE_INTERNATIONALS,
                beforeEnter : auth,
                component   : () => import('../components/services/internationals/ListInternationals.vue'),
            },
            {
                path        : `servicios/bloqueos`,
                name        : ROUTE_SERVICE_LOCKEDS,
                beforeEnter : auth,
                component   : () => import('../components/services/lockeds/ListLockeds.vue'),
            },
            {
                path        : `servicios/bloqueos/:idLocked/ver`,
                name        : ROUTE_SERVICE_LOCKED_SHOW,
                beforeEnter : auth,
                component   : () => import('../components/services/lockeds/Show.vue'),
                props       : true
            },
            {
                path        : `servicios/:service_type/:idService/editar`,
                name        : ROUTE_SERVICE_EDIT,
                beforeEnter : auth,
                component   : () => import('../components/services/EditService.vue'),
                props       : true
            },
            {
                path        : `servicios/:service_type/:idService/fechas-de-viaje`,
                name        : ROUTE_SERVICE_TRAVEL_DATE,
                beforeEnter : auth,
                component   : () => import('../components/services/travel-dates/TravelDates.vue'),
                props       : true
            },
            {
                path        : `servicios/:idTravelDate/lugares-de-salida`,
                name        : ROUTE_SERVICE_EXIT_PLACES,
                beforeEnter : auth,
                component   : () => import('../components/services/travel-dates/ExitPlaces.vue'),
                props       : true
            },
            {
                path        : `servicios/:idTravelDate/lugares-de-salida/:idExitPlace/precios`,
                name        : ROUTE_SERVICE_EXIT_PLACE_PRICES,
                beforeEnter : auth,
                component   : () => import('../components/services/travel-dates/ExitPlacePrices.vue'),
                props       : true
            },
            {
                path        : `servicios/:idTravelDate/unidades-de-viaje`,
                name        : ROUTE_SERVICE_TRAVEL_UNIT,
                beforeEnter : auth,
                component   : () => import('../components/services/travel-dates/TravelDateUnit.vue'),
                props       : true
            },
            
            /** URL de cotizador */
            {
                path        : `cotizador`,
                name        : ROUTE_SERVICE_QUOTE,
                beforeEnter : auth,
                component   : () => import('../components/quotes/Index.vue'),
                props       : true
            },
            {
                path        : `cotizador/crear`,
                name        : ROUTE_SERVICE_QUOTE_ADD,
                beforeEnter : auth,
                component   : () => import('../components/quotes/Store.vue'),
                props       : true
            },
            {
                path        : `cotizador/:idQuote/editar`,
                name        : ROUTE_SERVICE_QUOTE_EDIT,
                beforeEnter : auth,
                component   : () => import('../components/quotes/Edit.vue'),
                props       : true
            },
            {
                path        : `cotizador/:idQuote/ver`,
                name        : ROUTE_SERVICE_QUOTE_SHOW,
                beforeEnter : auth,
                component   : () => import('../components/quotes/Show.vue'),
                props       : true
            },

            /*  URL de reservaciones de HOTEL */
            {
                path        : `reservaciones/hoteles`,
                name        : ROUTE_RESERVATION_HOTEL_INDEX,
                beforeEnter : auth,
                component   : () => import('../components/reservations/hotels/Index.vue'),
                props       : true
            },
            {
                path        : `reservaciones/hoteles/agregar`,
                name        : ROUTE_RESERVATION_HOTEL_CREATE,
                beforeEnter : auth,
                component   : () => import('../components/reservations/hotels/Create.vue'),
                props       : true
            },
            // {
            //     path        : `reservaciones/hoteles/:idReservation/ver`,
            //     name        : ROUTE_RESERVATION_HOTEL_SHOW,
            //     beforeEnter : auth,
            //     component   : () => import('../components/reservations/hotels/Show.vue'),
            //     props       : true
            // },
            {
                path        : `reservaciones/hoteles/:idReservation/editar`,
                name        : ROUTE_RESERVATION_HOTEL_EDIT,
                beforeEnter : auth,
                component   : () => import('../components/reservations/hotels/Edit.vue'),
                props       : true
            },

            /*  URL de reservaciones de CIRCUIT */
            {
                path        : `reservaciones/circuitos`,
                name        : ROUTE_RESERVATION_CIRCUIT_INDEX,
                beforeEnter : auth,
                component   : () => import('../components/reservations/circuits/Index.vue'),
                props       : true
            }, 
            {
                path        : `reservaciones/circuitos/agregar`,
                name        : ROUTE_RESERVATION_CIRCUIT_CREATE,
                beforeEnter : auth,
                component   : () => import('../components/reservations/circuits/Create.vue'),
                props       : true
            },
            // {
            //     path        : `reservaciones/circuitos/:idReservation/ver`,
            //     name        : ROUTE_RESERVATION_CIRCUIT_SHOW,
            //     beforeEnter : auth,
            //     component   : () => import('../components/reservations/circuits/Show.vue'),
            //     props       : true
            // },
            {
                path        : `reservaciones/circuitos/:idReservation/editar`,
                name        : ROUTE_RESERVATION_CIRCUIT_EDIT,
                beforeEnter : auth,
                component   : () => import('../components/reservations/circuits/Edit.vue'),
                props       : true
            },

            /*  URL de reservaciones de DAYTRIPS */
            {
                path        : `reservaciones/pasadias`,
                name        : ROUTE_RESERVATION_DAYTRIPS_INDEX,
                beforeEnter : auth,
                component   : () => import('../components/reservations/daytrips/Index.vue'),
                props       : true
            },
            {
                path        : `reservaciones/pasadias/agregar`,
                name        : ROUTE_RESERVATION_DAYTRIPS_CREATE,
                beforeEnter : auth,
                component   : () => import('../components/reservations/daytrips/Create.vue'),
                props       : true
            },
            // {
            //     path        : `reservaciones/pasadias/:idReservation/ver`,
            //     name        : ROUTE_RESERVATION_DAYTRIPS_SHOW,
            //     beforeEnter : auth,
            //     component   : () => import('../components/reservations/daytrips/Show.vue'),
            //     props       : true
            // },
            {
                path        : `reservaciones/pasadias/:idReservation/editar`,
                name        : ROUTE_RESERVATION_DAYTRIPS_EDIT,
                beforeEnter : auth,
                component   : () => import('../components/reservations/daytrips/Edit.vue'),
                props       : true
            },

            /*  URL de reservaciones de TOURS */
            {
                path        : `reservaciones/tours`,
                name        : ROUTE_RESERVATION_TOURS_INDEX,
                beforeEnter : auth,
                component   : () => import('../components/reservations/tours/Index.vue'),
                props       : true
            },
            {
                path        : `reservaciones/tours/agregar`,
                name        : ROUTE_RESERVATION_TOURS_CREATE,
                beforeEnter : auth,
                component   : () => import('../components/reservations/tours/Create.vue'),
                props       : true
            },
            // {
            //     path        : `reservaciones/tours/:idReservation/ver`,
            //     name        : ROUTE_RESERVATION_TOURS_SHOW,
            //     beforeEnter : auth,
            //     component   : () => import('../components/reservations/tours/Show.vue'),
            //     props       : true
            // },
            {
                path        : `reservaciones/tours/:idReservation/editar`,
                name        : ROUTE_RESERVATION_TOURS_EDIT,
                beforeEnter : auth,
                component   : () => import('../components/reservations/tours/Edit.vue'),
                props       : true
            },

            /*  URL de reservaciones de INTERNATIONALS */
            {
                path        : `reservaciones/internacionales`,
                name        : ROUTE_RESERVATION_INTERNATIONALS_INDEX,
                beforeEnter : auth,
                component   : () => import('../components/reservations/internationals/Index.vue'),
                props       : true
            },
            {
                path        : `reservaciones/internacionales/agregar`,
                name        : ROUTE_RESERVATION_INTERNATIONALS_CREATE,
                beforeEnter : auth,
                component   : () => import('../components/reservations/internationals/Create.vue'),
                props       : true
            },
            // {
            //     path        : `reservaciones/internacionales/:idReservation/ver`,
            //     name        : ROUTE_RESERVATION_INTERNATIONALS_SHOW,
            //     beforeEnter : auth,
            //     component   : () => import('../components/reservations/internationals/Show.vue'),
            //     props       : true
            // },
            {
                path        : `reservaciones/internacionales/:idReservation/editar`,
                name        : ROUTE_RESERVATION_INTERNATIONALS_EDIT,
                beforeEnter : auth,
                component   : () => import('../components/reservations/internationals/Edit.vue'),
                props       : true
            },

            /*  URL de reservaciones de FLIGHTS */
            {
                path        : `reservaciones/vuelos`,
                name        : ROUTE_RESERVATION_FLIGHTS_INDEX,
                beforeEnter : auth,
                component   : () => import('../components/reservations/flights/Index.vue'),
                props       : true
            },
            {
                path        : `reservaciones/vuelos/agregar`,
                name        : ROUTE_RESERVATION_FLIGHTS_CREATE,
                beforeEnter : auth,
                component   : () => import('../components/reservations/flights/Create.vue'),
                props       : true
            },
            // {
            //     path        : `reservaciones/vuelos/:idReservation/ver`,
            //     name        : ROUTE_RESERVATION_FLIGHTS_SHOW,
            //     beforeEnter : auth,
            //     component   : () => import('../components/reservations/flights/Show.vue'),
            //     props       : true
            // },
            {
                path        : `reservaciones/vuelos/:idReservation/editar`,
                name        : ROUTE_RESERVATION_FLIGHTS_EDIT,
                beforeEnter : auth,
                component   : () => import('../components/reservations/flights/Edit.vue'),
                props       : true
            },

            /*  URL de reservaciones de OTHER SERVICES */
            { 
                path        : `reservaciones/otros-servicios`,
                name        : ROUTE_RESERVATION_OTHER_SERVICE_INDEX,
                beforeEnter : auth,
                component   : () => import('../components/reservations/other-services/Index.vue'),
                props       : true
            },
            {
                path        : `reservaciones/otros-servicios/agregar`,
                name        : ROUTE_RESERVATION_OTHER_SERVICE_CREATE,
                beforeEnter : auth,
                component   : () => import('../components/reservations/other-services/Create.vue'),
                props       : true
            },
            // {
            //     path        : `reservaciones/otros-servicios/:idReservation/ver`,
            //     name        : ROUTE_RESERVATION_OTHER_SERVICE_SHOW,
            //     beforeEnter : auth,
            //     component   : () => import('../components/reservations/other-services/Show.vue'),
            //     props       : true
            // },
            {
                path        : `reservaciones/otros-servicios/:idReservation/editar`,
                name        : ROUTE_RESERVATION_OTHER_SERVICE_EDIT,
                beforeEnter : auth,
                component   : () => import('../components/reservations/other-services/Edit.vue'),
                props       : true
            },

            /*  URL de reservaciones de TRANSFERS */
            { 
                path        : `reservaciones/traslados`,
                name        : ROUTE_RESERVATION_TRANSFER_INDEX,
                beforeEnter : auth,
                component   : () => import('../components/reservations/transfers/Index.vue'),
                props       : true
            },
            {
                path        : `reservaciones/traslados/agregar`,
                name        : ROUTE_RESERVATION_TRANSFER_CREATE,
                beforeEnter : auth,
                component   : () => import('../components/reservations/transfers/Create.vue'),
                props       : true
            },
            {
                path        : `reservaciones/traslados/:idReservation/editar`,
                name        : ROUTE_RESERVATION_TRANSFER_EDIT,
                beforeEnter : auth,
                component   : () => import('../components/reservations/transfers/Edit.vue'),
                props       : true
            },

            /*  URL de reservaciones de Empaquetados */
            {
                path        : `reservaciones/empaquetados`,
                name        : ROUTE_RESERVATION_PACKAGED_INDEX,
                beforeEnter : auth,
                component   : () => import('../components/reservations/packaged/Index.vue'),
                props       : true
            },
            {
                path        : `reservaciones/empaquetados/agregar`,
                name        : ROUTE_RESERVATION_PACKAGED_CREATE,
                beforeEnter : auth,
                component   : () => import('../components/reservations/packaged/Create.vue'),
                props       : true
            },
            {
                path        : `reservaciones/empaquetados/:idReservation/editar`,
                name        : ROUTE_RESERVATION_PACKAGED_EDIT,
                beforeEnter : auth,
                component   : () => import('../components/reservations/packaged/Edit.vue'),
                props       : true
            },
            // {
            //     path        : `reservaciones/empaquetados/:idReservation/ver`,
            //     name        : ROUTE_RESERVATION_PACKAGED_SHOW,
            //     beforeEnter : auth,
            //     component   : () => import('../components/reservations/packaged/Show.vue'),
            //     props       : true
            // },
            // {
            //     path        : `reservaciones/empaquetados/:idReservation/editar`,
            //     name        : ROUTE_RESERVATION_PACKAGED_EDIT,
            //     beforeEnter : auth,
            //     component   : () => import('../components/reservations/packaged/Edit.vue'),
            //     props       : true
            // },
            
            /*  URL de reservaciones de TRANSFERS */
            { 
                path        : `reservaciones/bloqueos`,
                name        : ROUTE_RESERVATION_LOCKEDS_INDEX,
                beforeEnter : auth,
                component   : () => import('../components/reservations/lockeds/Index.vue'),
                props       : true
            },
            {
                path        : `reservaciones/bloqueos/agregar`,
                name        : ROUTE_RESERVATION_LOCKEDS_CREATE,
                beforeEnter : auth,
                component   : () => import('../components/reservations/lockeds/Create.vue'),
                props       : true
            },
            {
                path        : `reservaciones/bloqueos/:idReservation/editar`,
                name        : ROUTE_RESERVATION_LOCKEDS_EDIT,
                beforeEnter : auth,
                component   : () => import('../components/reservations/lockeds/Edit.vue'),
                props       : true
            },

            /**Reservation Pass */
            {
                path        : `reservacion/:idReservation/pase`,
                name        : ROUTE_RESERVATION_PASS_INDEX,
                beforeEnter : auth,
                component   : () => import('../components/reservations/ReservationPass.vue'),
                props       : true
            },

            /**Reservation Notes */
            // {
            //     path        : `reservaciones/:idReservation/notas`,
            //     name        : ROUTE_RESERVATION_NOTES,
            //     beforeEnter : auth,
            //     component   : () => import('../components/reservations/Notes.vue'),
            //     props       : true
            // },

            /**Reservation EDIT PEOPLE */
            // {
            //     path        : `reservaciones/:idReservation/pasajeros`,
            //     name        : ROUTE_RESERVATION_PEOPLE,
            //     beforeEnter : auth,
            //     component   : () => import('../components/reservations/EditPeople.vue'),
            //     props       : true
            // },

            /**Reservation Payments */
            // {
            //     path        : `reservaciones/:idReservation/pago`,
            //     name        : ROUTE_RESERVATION_PAYMENTS,
            //     beforeEnter : auth,
            //     component   : () => import('../components/reservations/ReservationPayment.vue'),
            //     props       : true
            // },
            // {
            //     path        : `reservaciones/:idReservation/lista-pagos`,
            //     name        : ROUTE_RESERVATION_LIST_PAYMENTS,
            //     beforeEnter : auth,
            //     component   : () => import('../components/reservations/ReservationListPayment.vue'),
            //     props       : true
            // },
            {
                path        : `reservaciones/:idReservation/pago-a-brookers`,
                name        : ROUTE_RESERVATION_PAYMENT_TO_BROOKER,
                beforeEnter : auth,
                component   : () => import('../components/reservations/ReservationPaymentToBrooker.vue'),
                props       : true
            },
            {
                path        : `reservaciones/:idReservation/pago-a-brookers/:idPayment/ver`,
                name        : ROUTE_RESERVATION_PAYMENT_TO_BROOKER_VIEW,
                beforeEnter : auth,
                component   : () => import('../components/reservations/ReservationPaymentToBrookerView.vue'),
                props       : true
            },

            /**Reservation Cancel */
            {
                path        : `reservacion/cancelar/:idReservation`,
                name        : ROUTE_RESERVATION_CANCEL,
                beforeEnter : auth,
                component   : () => import('../components/reservations/ReservationCancel.vue'),
                props       : true
            },

            /** Pagos Multiples */
            {
                path        : `pagos-multiples`,
                name        : ROUTE_PAYMENT_MULTIPLE,
                beforeEnter : auth,
                component   : () => import('../components/payment-multiples/Index.vue'),
                props       : true
            },
            {
                path        : `pagos-multiples/agregar`,
                name        : ROUTE_PAYMENT_MULTIPLE_ADD,
                beforeEnter : auth,
                component   : () => import('../components/payment-multiples/Store.vue'),
                props       : true
            },

            /** CHANGE SEAT */
            {
                path        : `reservaciones/:idReservation/seleccionar-asientos`,
                name        : ROUTE_RESERVATION_SELECT_SEAT_INDEX,
                beforeEnter : auth,
                component   : () => import('../components/reservations/partials/SelectSeatPeopleUnit.vue'),
                props       : true
            },

            /** BOXES */
            {
                path        : `cajas`,
                name        : ROUTE_BRANCH_BOXES,
                beforeEnter : auth,
                component   : () => import('../components/boxes/BranchBoxes.vue'),
                props       : true
            },
            {
                path        : `caja/historial/:idBox`,
                name        : ROUTE_BOX_HISTORY,
                beforeEnter : auth,
                component   : () => import('../components/boxes/CutHistory.vue'),
                props       : true
            },
            {
                path        : `caja/historial/:idBox/movimientos/:idBoxCut`,
                name        : ROUTE_BOX_HISTORY_MOVE_LIST,
                beforeEnter : auth,
                component   : () => import('../components/boxes/BoxMoveList.vue'),
                props       : true
            },

            /** SAVING */
            {
                path        : `ahorros`,
                name        : ROUTE_SAVING,
                beforeEnter : auth,
                component   : () => import('../components/savings/Saving.vue'),
                props       : true
            },

            //PROFILE
            {
                path        : `perfiles`,
                name        : ROUTE_PROFILE_LIST,
                beforeEnter : auth,
                component   : () => import('../components/profiles/ProfileList.vue'),
                props       : true
            },
            {
                path        : `perfiles/agregar`,
                name        : ROUTE_PROFILE_ADD,
                beforeEnter : auth,
                component   : () => import('../components/profiles/ProfileCreate.vue'),
                props       : true
            },
            {
                path        : `perfiles/:idProfile/editar`,
                name        : ROUTE_PROFILE_EDIT,
                beforeEnter : auth,
                component   : () => import('../components/profiles/ProfileEdit.vue'),
                props       : true
            },

            // Promotions
            {
                path        : 'promociones',
                name        : ROUTE_PROMOTION_FOLDER,
                beforeEnter : auth,
                component   : () => import('../components/promotions/PromotionFolder.vue')
            },
            {
                path        : 'promociones/:idFolder/lista',
                name        : ROUTE_PROMOTIONS,
                beforeEnter : auth,
                component   : () => import('../components/promotions/ListPromotions.vue'),
                props       : true
            },
            // {
            //     path        : 'promociones/agregar',
            //     name        : ROUTE_PROMOTION_ADD,
            //     beforeEnter : auth,
            //     component   : () => import('../components/promotions/AddPromotion.vue'),
            //     props       : {'idPromotion' : 0}
            // },
            // {
            //     path        : 'promociones/:idPromotion/editar',
            //     name        : ROUTE_PROMOTION_EDIT,
            //     beforeEnter : auth,
            //     component   : () => import('../components/promotions/AddPromotion.vue'),
            //     props       : true
            // }, 

            // Coupons
            {
                path        : 'cupones',
                name        : ROUTE_COUPONS,
                beforeEnter : auth,
                component   : () => import('../components/coupons/ListCoupons.vue')
            },
            {
                path        : 'cupones/agregar',
                name        : ROUTE_COUPON_ADD,
                beforeEnter : auth,
                component   : () => import('../components/coupons/AddCoupon.vue'),
                props       : {'idCoupon' : 0}
            },
            {
                path        : 'cupones/:idCoupon/editar',
                name        : ROUTE_COUPON_EDIT,
                beforeEnter : auth,
                component   : () => import('../components/coupons/AddCoupon.vue'),
                props       : true
            }, 
            

            // Banks
            {
                path        : `cuentas`,
                name        : ROUTE_BANKS,
                beforeEnter : auth,
                component   : () => import('../components/cards/CardList.vue')
            },
            {
                path        : `cuentas/agregar`,
                name        : ROUTE_BANK_ADD,
                beforeEnter : auth,
                component   : () => import('../components/cards/CardAdd.vue'),
                props       : true
            },
            {
                path        : `cuentas/:idBank/editar`,
                name        : ROUTE_BANK_EDIT,
                beforeEnter : auth,
                component   : () => import('../components/cards/CardAdd.vue'),
                props       : true
            },
            {
                path        : `cuentas/:idBank/movimientos`,
                name        : ROUTE_BANK_HISTORY_MOVE_LIST,
                beforeEnter : auth,
                component   : () => import('../components/cards/BankMoveList.vue'),
                props       : true
            },

            /**Configurations */
            {
                path        : `configuracion`,
                name        : ROUTE_CONFIGURATIONS,
                beforeEnter : auth,
                component   : () => import('../components/configurations/GeneralConfiguration.vue'),
                props       : true
            },
            {
                path        : `actividades`,
                name        : ROUTE_ACTIVITY,
                beforeEnter : auth,
                component   : () => import('../components/activities/Activities.vue'),
                props       : true
            },

            //Reports
            {
                path        : `reportes`,
                name        : ROUTE_REPORTS,
                beforeEnter : auth,
                component   : () => import('../components/reports/Reports.vue'),
                props       : true
            },

            /**Profile Admin */
            {
                path        : `perfil`,
                name        : ROUTE_PROFILE,
                beforeEnter : auth,
                component   : () => import('../components/configurations/profile.vue'),
                props       : true
            },
        ],
    },


    /**Client DASHBOARD */
    {
        path: '/cliente',
        name: ROUTE_CLIENT_TEMPLATE,
        component: () => import('../components/frontend/partials/TemplateDashboard.vue'),
        beforeEnter: auth,
        redirect: '/cliente/dashboard',
        children:[
            {
                path        : 'dashboard',
                name        : ROUTE_CLIENT_DASHBOARD,
                beforeEnter : auth,
                component   : () => import('../components/frontend/dashboard/Dashboard.vue')
            },
            {
                path        : 'mis-viajes',
                name        : ROUTE_CLIENT_RESERVATIONS,
                beforeEnter : auth,
                component   : () => import('../components/frontend/dashboard/reservation/Reservation.vue')
            },
            {
                path        : 'mi-perfil',
                name        : ROUTE_CLIENT_PROFILE,
                beforeEnter : auth,
                component   : () => import('../components/frontend/dashboard/profile/Profile.vue')
            },
            {
                path        : 'pagos',
                name        : ROUTE_CLIENT_PAYMENTS,
                beforeEnter : auth,
                component   : () => import('../components/frontend/dashboard/payment/Payment.vue')
            },
        ],
    },
]
 

const router = new VueRouter({
    mode: 'history',
    base: process.env.BASE_URL,
    routes
})
 
export default router